<template>
    <section class="w-full h-full px-4 bg-[url('/home/home_bg.png')] bg-center bg-cover bg-no-repeat bg-blend-multiply bg-secondary/80">
        <WrapperLayout>
            
            <!-- Hero -->
            <div class="pb-12 text-center md:pb-16">

                <!-- Titolo -->
                <h1 class="heading !text-white" data-aos="zoom-y-out" data-aos-delay="150">
                    Diventa il Re del <br class="max-lg:hidden">Fantacalcio in Italia!
                </h1>

                <!-- Sezione sottostante -->
                <div class="mx-auto max-w-3xl">

                    <!-- Sottotitolo-->
                    <p class="mb-8 text-lg text-gray-200" data-aos="zoom-y-out" data-aos-delay="300">
                        Sfida l'élite del fantacalcio, crea la squadra dei tuoi sogni e conquista la vittoria.
                        Gestisci il tuo team con strategia e intelligenza, seleziona i migliori talenti e dimostra chi è
                        il vero maestro del gioco. Con ricchi montepremi in palio, sei pronto a scrivere la tua leggenda?
                    </p>

                    <!-- Sezione dei pulsanti -->
                    <div class="flex items-center justify-center gap-4 sm:gap-6" data-aos="zoom-y-out" data-aos-delay="450">

                        <!-- Pulsante per acquistare -->
                        <a href="#registration" class="flex flex-1 items-center justify-center cursor-pointer gap-2 py-2 px-4 sm:px-6 bg-primary rounded-lg">
                            <span class="text-sm font-semibold text-gray-700">Registrati Ora!</span>
                        </a>

                        <!-- Pulsante per saperne di più -->
                        <a href="#about" class="flex flex-1 items-center justify-center gap-2 py-2 px-4 sm:px-6 bg-white text-gray-800 shadow hover:bg-gray-50 rounded-lg">
                            <span class="text-sm font-semibold text-gray-700">Scopri di più</span>
                        </a>

                    </div>
                    
                </div>

            </div>

            <!-- Immagine -->
            <div class="max-w-xl mx-auto" data-aos="zoom-in" data-aos-delay="600">
                <img src="/home/home_image.png" alt="Premi" class="max-w-full rounded-2xl shadow-sm">
            </div>

        </WrapperLayout>
    </section>
</template>

<script setup>
</script>