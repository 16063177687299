export default {
    code: 'en',
    messages: {
        alpha: "The field may only contain alphabetic characters",
        alpha_num: "The field may only contain alphanumeric characters",
        alpha_dash: "The field may contain alphanumeric characters as well as dashes and underscores",
        alpha_spaces: "The field may only contain alphanumeric characters as well as spaces",
        between: "The field must be between 0:{min} and 1:{max}",
        confirmed: "The field does not match",
        digits: "The field must be numeric and contain exactly 0:{length} digits",
        dimensions: "The field must be 0:{width} x 1:{height}",
        email: "The field must be a valid email address",
        not_one_of: "The field must have a valid value",
        ext: "The field must be a valid file",
        image: "The field must be an image",
        integer: "The field must be a number",
        is_not: "The field is not valid",
        length: "The length of the field must be 0:{length}",
        max_value: "The field must be less than or equal to 0:{max}",
        max: "The field may not be longer than 0:{length} characters",
        mimes: "The field must have a valid file type",
        min_value: "The field must be greater than or equal to 0:{min}",
        min: "The field must be at least 0:{length} characters",
        numeric: "The field may only contain numeric characters",
        one_of: "The field must have a valid value",
        regex: "The field format is invalid",
        required: "The field is required",
        required_if: "The field is required",
        size: "The field must be less than 0:{size}KB"
    }
}