<template>
    <WrapperLayout>

        <!-- Intestazione -->
        <h1 class="mb-6 text-center text-5xl font-bold [border-image:linear-gradient(to_right,transparent,theme(colors.slate.300/.8),transparent)1] md:text-6xl aos-init aos-animate">
            Registrati ora
        </h1>

        <FormGenerator
            ref="formRef"
            class="w-full"
            :tKey="'registration'"
            :useCanDirective="false"
            :fields="formModel"
            :key="formKey"
            :blocked="blocked"
            @submit="onSubmit"
        />

    </WrapperLayout>
</template>

<script setup>
    
    // based imports
    import { inject, ref } from "vue";

    // Middleware and error handler import and services
    import { handleFormErrors } from '@/helpers/errorHandler';

    // http service
    const HttpService = inject('HttpService');

    // definisco il modello per il form
    const formModel = ref({
        name: {
            key: "name",
            model: null,
            type: "text",
            rules: "required"
        },
        email: {
            key: "email",
            model: null,
            type: "text",
            rules: "required|email"
        },
        team_name: {
            key: "team_name",
            model: null,
            type: "text",
            rules: "required"
        },
        tax_data: {
            key: "tax_data",
            model: null,
            type: "text",
            rules: { 
                required: true,
                regex: /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/ 
            }
        },
        tel: {
            key: "tel",
            model: null,
            type: "text",
            rules: "required"
        },
        address: {
            key: "address",
            model: null,
            type: "text",
            rules: "required"
        },
        city: {
            key: "city",
            model: null,
            type: "text",
            rules: "required"
        },
        postal_code: {
            key: "postal_code",
            model: null,
            type: "text",
            rules: { 
                required: true,
                regex: /^[0-9]{5}$/ 
            }
        }
    });

    // definisco il formKey, il riferimento e il loading
    const formKey = ref(0);
    const formRef = ref(null);
    const blocked = ref(false);

    // definisco la funzione per il submit
    const onSubmit = handleFormErrors(async (values) => {

        // setto il loading a true
        blocked.value = true;

        // recupero event
        const { event = {} } = values;

        // effettuo la chiamata
        await HttpService.post('register', event);

        blocked.value = false;
        
    }, { 
        onError: () => blocked.value = false,
        successMessage: "Controlla la tua casella email per confermare la registrazione",
        errorMessage: "I termini di iscrizione risultano già chiusi, contatta l'organizzazione per ulteriori informazioni"
    });

</script>