
<template>

    <header class="fixed top-4 z-30 w-full md:top-6">
        <div class="mx-auto max-w-6xl px-4 sm:px-6">
            <div class="relative flex items-center justify-between gap-3 h-16 rounded-2xl bg-white/90 dark:bg-surface-900 px-3 shadow-lg shadow-black/[0.03] backdrop-blur-sm before:pointer-events-none before:absolute before:inset-0 before:rounded-[inherit] before:border before:border-transparent before:[background:linear-gradient(theme(colors.gray.100),theme(colors.gray.200))_border-box] before:[mask-composite:exclude_!important] before:[mask:linear-gradient(white_0_0)_padding-box,_linear-gradient(white_0_0)]">
                
                <!-- Logo -->
                <div class="flex flex-1 items-center">
                    <RouterLink to="/" class="flex items-center gap-2">
                        <img 
                            src="/logo_orizzontale.png" 
                            alt="Fanta Blondin" 
                            class="w-full max-w-28 dark:filter dark:invert dark:brightness-0 dark:rounded-lg"
                        >
                    </RouterLink>
                </div>

                <!-- End parts -->
                <div class="flex flex-1 items-center justify-end gap-3">
                    
                    <!-- Button per registrazione -->
                    <a href="#registration" class="flex items-center gap-2 py-2 px-4 sm:px-6 bg-primary rounded-lg">
                        <span class="text-sm font-semibold text-gray-700">Registrati Ora!</span>
                    </a>

                    <!-- Button per il cambio tema -->
                    <ToggleTheme :size="'xs'" v-if="!isLoggedIn" />

                    <!-- Bottone per mostrare la toolbar solo se loggati -->
                    <Button 
                        icon="pi pi-bars"
                        v-if="isLoggedIn"
                        @click="visible = !visible"
                    />

                </div>

            </div>
        </div>
    </header>

    <Drawer v-model:visible="visible" v-if="isLoggedIn">
        
        <!-- Template header -->
        <template #header>
            <div class="flex items-center gap-2">

                <!-- Button Logout @click="logout"-->
                <Button 
                    icon="pi pi-sign-out"
                    class="flex-auto"
                    severity="danger"
                    v-tooltip.top="$t('general.logout')"
                    text
                    @click="useAuthStore().logout()"
                />

                <!-- Informazioni utente -->
                <div class="flex flex-col justify-center" v-if="userData != null">
                    <span class="font-light tracking-widest leading-none">{{ userData?.username }}</span>
                </div>

            </div>
        </template>

        <!-- Template body -->
        <section name="body" class="overflow-y-auto max-h-full" v-if="items && Array.isArray(items) && items.length > 0">
            <ul class="overflow-y-auto max-h-full space-y-6 lg:space-y-8 pt-4">
                <template v-for="(item, index) in items" :key="index">

                    <template v-if="item?.items && Array.isArray(item?.items)">
                        
                        <!-- sezione per apertura/chiusura del menu a discesa -->
                        <li>
                            
                            <div 
                                v-ripple
                                class="py-2 flex items-center justify-between cursor-pointer p-ripple" 
                                @click="toggleSection(item)"
                                
                            >
                            
                                <!-- Icona e Label -->
                                <div class="pl-1 inline-flex gap-2 items-center justify-start">
                                    
                                    <!-- Icona -->
                                    <span v-if="item?.icon" :class="item?.class || ''" class="pr-3 material-symbols-outlined material-symbols-font-300">
                                        {{ item?.icon }}
                                    </span>

                                    <!-- Label -->
                                    <span class="tracking-widest" v-if="item?.label">
                                        {{ $te(`headers.${item?.label}.title`) ? $t(`headers.${item?.label}.title`) : item?.label }}
                                    </span>

                                </div>

                                <!-- Icona per apertura/chiusura -->
                                <span class="material-symbols-outlined"> {{ item?.expanded ? 'expand_less' : 'expand_more' }} </span>
                            </div>

                            <!-- ul per i figli -->
                            <transition name="fade">
                                <ul class="list-none px-2 pb-2 pt-4 m-0 overflow-hidden space-y-2" v-if="item?.expanded">
                                    <AppLink 
                                        v-for="(subItem, subIndex) in item?.items"
                                        :key="subIndex"
                                        v-ripple
                                        :to="subItem?.name"
                                        class="duration-150 transition-colors p-ripple"
                                        @click="visible = false"
                                    > 
                                        
                                        <!-- Icona -->
                                        <span v-if="subItem?.icon" :class="subItem?.class || ''" class="material-symbols-outlined material-symbols-font-300">
                                            {{ subItem.icon }}
                                        </span>

                                        <!-- Label -->
                                        <span class="font-light tracking-widest" v-if="subItem?.label">
                                            {{ $te(`headers.${subItem?.label}.title`) ? $t(`headers.${subItem?.label}.title`) : subItem?.label }}
                                        </span>

                                    </AppLink>
                                </ul>
                            </transition>
                        
                        </li>

                    </template>

                    <template v-else>
                        <li>
                            <AppLink 
                                v-ripple
                                :to="item?.name"
                                class="duration-150 transition-colors p-ripple"
                                @click="visible = false"
                                
                            >
                                <!-- Icona -->
                                <span v-if="item?.icon" :class="item?.class || ''" class="mr-3 material-symbols-outlined material-symbols-font-100">
                                    {{ item?.icon }}
                                </span>

                                <!-- Label -->
                                <span class="tracking-widest" v-if="item?.label">
                                    {{ $te(`headers.${item?.label}.title`) ? $t(`headers.${item?.label}.title`) : item?.label }}
                                </span>

                            </AppLink>
                        </li>
                    </template>

                </template>
            </ul>
        </section>

        <!-- Template footer -->
        <template #footer>
            <div class="flex items-center mb-3 justify-between gap-2">
                <label>Lingua </label>
                <LanguageSwitcher />
            </div>
            <div class="flex items-center mb-6 justify-between gap-2">
                <label>Modalità </label>
                <ToggleTheme />
            </div>
            <div class="flex items-center gap-2">
                <img src="/logo_orizzontale.png"  alt="Fanta Blondin" class="opacity-50 w-28 py-1.5 mx-auto" lazy>
            </div>
        </template>

    </Drawer>

</template>

<script setup>

    // based imports
    import { ref, computed } from "vue";
    import { useRoute } from 'vue-router';

    // services imports
    import { useDark } from '@vueuse/core';
    import { handleErrorsSilent } from '@/helpers/errorHandler';

    // store imports
    import { useAuthStore } from '@/stores/auth.js';
    
    // components imports
    import ToggleTheme from '@/components/reusable/ToggleTheme.vue';
    import LanguageSwitcher from '@/components/reusable/LanguageSwitcher.vue';         

    // navigations imports 
    import navigationsList from "./utils/navigationsList";

    // dichiaro una variabile per il dark
    const isDark = useDark();

    // dichiaro la route
    const route = useRoute();

    // dichiaro una variabile statica per i test di login
    const isLoggedIn = computed(() => useAuthStore().getAuthStatus);
    const userData = computed(() => useAuthStore().getUser);

    // definisco gli oggetti per la sidebar
    const visible = ref(false);
    const items = ref(navigationsList || []);

    // funzione per il toggle
    const toggleSection = handleErrorsSilent(async (item) => {
        
        if(!item || typeof item !== 'object' || Object.keys(item).length === 0) {
            throw new Error("Item not defined");
        }

        // controllo se esiste item.items
        if(item?.items && Array.isArray(item?.items)) {
            item.expanded = !item.expanded;
        }

    });

</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.5s;
    }
    .fade-enter-from, .fade-leave-to {
        opacity: 0;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
</style>