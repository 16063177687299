import ToastBus from '@/services/globalToastBus';
import i18n from "@/i18n";

// Definiamo il multilingua
const { t } = i18n.global;

class ErrorHandler {
  
  constructor(config = {}) {
    this.successMessage = config.successMessage || 'general.success';
    this.errorMessage = config.errorMessage || 'general.error';
    this.hideMessage = config.hideMessage || false;
    this.onError = typeof config.onError === 'function' ? config.onError : null;
  }

  // Metodo privato per gestire il successo e l'errore
  async #handle(fn, ...args) {
    try {
      const result = await fn(...args);
      this.#handleSuccess();
      return result;
    } catch (error) {
      this.#handleError(error);
      this.#executeOnError(error);
      throw error;
    }
  }

  // Esegui la funzione con gestione degli errori
  execute(fn, ...args) {
    return this.#handle(fn, ...args);
  }

  // Esegui la funzione di form con gestione degli errori
  executeForm(fn, ...args) {
    this.#validateArgs(args);
    return this.#handle(fn, ...args);
  }

  // Validazione degli argomenti
  #validateArgs(args) {
    if (!Array.isArray(args) || args.length === 0) {
      throw new Error('Invalid args');
    }

    const values = args[0];

    if (!values || typeof values !== 'object' || Object.keys(values).length === 0) {
      throw new Error('Invalid event');
    }

    if (!values.event || typeof values.event !== 'object' || Object.keys(values.event).length === 0) {
      throw new Error('Invalid event object');
    }
  }

  // Gestione dell'errore
  #handleError(error) {
    if (import.meta.env.VITE_DEVELOPMENT === 'true') {
      console.error(error);
    }
    if (!this.hideMessage && this.errorMessage) {
      ToastBus.emit(t(this.errorMessage), 'error');
    }
  }

  // Gestione del successo
  #handleSuccess() {
    if (!this.hideMessage && this.successMessage) {
      ToastBus.emit(t(this.successMessage), 'success');
    }
  }

  // Esecuzione del callback di errore
  #executeOnError(error) {
    if (this.onError) {
      try {
        this.onError(error);
      } catch (onErrorErr) {
        this.#handleError(onErrorErr);
      }
    }
  }
}

// Funzione base
export const handleErrors = (fn, config = {}) => {
  const errorHandler = new ErrorHandler(config);
  return (...args) => errorHandler.execute(fn, ...args);
};

// Funzione di form
export const handleFormErrors = (fn, config = {}) => {
  const errorHandler = new ErrorHandler(config);
  return (...args) => errorHandler.executeForm(fn, ...args);
};

// Funzione di base senza toast 
export const handleErrorsSilent = (fn, config = {}) => {
  const errorHandler = new ErrorHandler({ ...config, hideMessage: true });
  return (...args) => errorHandler.execute(fn, ...args);
};
