<template>
  
  <!-- Toast component -->
  <ToastWrapper />

  <!-- Dinamic Dialog component -->
  <DynamicDialogWrapper />

  <!-- Main component -->
  <div class="flex flex-col h-screen">
    <ToolBar/>
    <div class="grow overflow-y-auto overflow-x-hidden">
      <RouterView/>
    </div>
  </div>

  <!-- ScrollTop component -->
  <ScrollTop />

</template>

<script setup>

  // based imports
  import { onMounted, computed } from 'vue';

  // services imports
  import { useDark } from '@vueuse/core';

  // import Toast component
  import ToastWrapper from '@/components/reusable/ToastWrapper.vue';

  // import Dinamic Dialog component
  import DynamicDialogWrapper from '@/components/reusable/DinamicDialogWrapper.vue';

  // navigations components imports
  import ToolBar from '@/components/navigations/ToolBar.vue';

  // store imports
  import { useAuthStore } from '@/stores/auth.js';

  // dichiaro una variabile statica per i test di login
  const isLoggedIn = computed(() => useAuthStore().getAuthStatus);

  // hoock di caricamento
  onMounted(() => {
    useDark();
  });

</script>