<template>

    <!-- Caso in cui non ci sono errori -->
    <div :class="styleComputed" class="text-sm">
        <div 
            v-for="(field, key) in localData"
            :key="key"
            v-show="field && !field?.notShow"
            v-can="{ role: field?.canDirective, useCanDirective }"
            :class="field?.class || null"
        >
            <div class="w-full flex items-start justify-center gap-2 flex-col">

                <!-- Label and Error Message -->
                <label :for="field?.key" class="flex items-center gap-2 flex-wrap pl-2">
                    
                    <!-- Field information -->
                    <div v-if="field?.label && Array.isArray(field?.label) && field?.label.length > 0" class="space-x-1">
                        <span v-for="(singleField, index) in field?.label" :key="index">
                            {{ $t(field?.localTranslate ? singleField : `${tKey ? `${tKey}.` : ''}${singleField}`) }}
                        </span>
                    </div>

                    <div v-else-if="field?.label && !Array.isArray(field?.label) && field?.label !== '' && field?.label !== null">
                        {{ $t(field?.localTranslate ? field : `${tKey ? `${tKey}.` : ''}${field?.label}`) }}
                    </div>

                    <div v-else>
                        {{ $t(field?.localTranslate ? field : `${tKey ? `${tKey}.` : ''}${field?.key}`) }}
                    </div>

                    <!-- Error message -->
                    <ErrorMessage as="div" :name="field?.key" v-slot="{ message }">
                        <Message severity="error" class="text-xs">{{ message }}</Message>
                    </ErrorMessage>

                </label>

                <!-- Renderizzo il field con il template per le varie tipologie -->
                <Field
                    v-model="field.model"
                    :name="field?.key"
                    :class="field?.class || null"
                    :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                    :rules="!field?.notShow && field?.rules ? field?.rules : null"
                    autocomplete="on"
                >
                    
                    <!-- Caso in cui è un Dropdown -->
                    <template v-if="field?.type === 'dropdown'">
                        <Select
                            v-model="field.model"
                            v-bind="field.bind"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        >
                            
                            <!-- Template per il valore -->
                            <template #value="slotProps" v-if="field?.bind?.templateConfig && typeof field?.bind?.templateConfig === 'object' && field?.bind?.templateConfig?.labelsFormatter && typeof field?.bind?.templateConfig?.labelsFormatter === 'function'">
                                    
                                <!-- Mostro il placeholder -->
                                <div v-if="slotProps?.value === null || slotProps?.value === undefined">
                                    {{ field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`) }}
                                </div>

                                <!-- Mostro il valore -->
                                <div v-else v-html="field?.bind?.templateConfig?.labelsFormatter(slotProps?.value, true)"></div>

                            </template>

                            <!-- Template per l'opzione -->
                            <template #option="slotProps" v-if="field?.bind?.templateConfig && typeof field?.bind?.templateConfig === 'object' && field?.bind?.templateConfig?.labelsFormatter && typeof field?.bind?.templateConfig?.labelsFormatter === 'function'">
                                <div v-html="field?.bind?.templateConfig?.labelsFormatter(slotProps?.option, false)"></div>
                            </template>

                        </Select>
                    </template>

                    <!-- Caso in cui è una multiselect -->
                    <template v-else-if="field?.type === 'multiselect'">
                        <MultiSelect 
                            v-model="field.model"
                            v-bind="field.bind"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        />
                    </template>

                    <!-- Caso in cui è un Calendar -->
                    <template v-else-if="field?.type === 'calendar'">
                        <DatePicker
                            v-model="field.model"
                            v-bind="field.bind || {}"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        />
                    </template>

                    <!-- Caso in cui è un numero -->
                    <template v-else-if="field?.type === 'number'">
                        <InputNumber 
                            v-model="field.model"
                            v-bind="field.bind || {}"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        />
                    </template>

                    <!-- Caso in cui è un toggle -->
                    <template v-else-if="field?.type === 'toggle'">
                        <ToggleButton 
                            v-model="field.model" 
                            v-bind="field.bind || {}"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        />
                    </template>

                    <!-- Caso in cui è un SelectButton -->
                    <template v-else-if="field?.type === 'selectbutton'">
                        <SelectButton 
                            v-model="field.model"
                            v-bind="field.bind || {}"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        />
                    </template>

                    <!-- Caso in cui è un textarea -->
                    <template v-else-if="field?.type === 'textarea'">
                        <Textarea 
                            v-model="field.model"
                            v-bind="field.bind || {}"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                            class="w-full"
                            :inputProps="{ autocomplete: true }"
                        />
                    </template>

                    <!-- Caso in cui è una password -->
                    <template v-else-if="field?.type === 'password'">
                        <IconField class="w-full">
                            <InputIcon v-if="field?.icon && field?.icon !== null && field?.icon !== undefined" :class="typeof field?.icon === 'string' ? field.icon : [field?.icon?.value, field?.icon?.class]" />
                            <Password 
                                v-model="field.model" 
                                v-bind="field.bind || {}" 
                                @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                                :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                                :inputProps="{ autocomplete: true }"
                                class="w-full"
                            />

                        </IconField>
                    </template>

                    <!-- Caso in cui è uno slider -->
                    <template v-else-if="field?.type === 'slider'">
                        <Slider 
                            v-model="field.model"
                            v-bind="field.bind || {}"
                            @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                            class="w-full max-w-[97%]"
                        />
                        <div class="w-full text-right text-sm text-surface-400 mt-1">{{ field.model === null ? 0 : field.model }}%</div>
                    </template>

                    <!-- Caso di default (input txt) -->
                    <template v-else>
                        <IconField class="w-full">

                            <InputIcon v-if="field?.icon && field?.icon !== null && field?.icon !== undefined" :class="typeof field?.icon === 'string' ? field.icon : [field?.icon?.value, field?.icon?.class]" />

                            <InputText 
                                v-model="field.model"
                                v-bind="field.bind || {}"
                                @change="field?.bind?.onchange && typeof field?.bind?.onchange === 'function' ? field.bind?.onchange($event) : null"
                                :placeholder="field?.placeholder ? $t(`${tKey}.${field?.placeholder}`) : $t(`${tKey}.${field?.key}`)"
                                class="w-full"
                            />

                        </IconField>
                    </template>
                    
                </Field>

            </div>
        </div>
    </div>

</template>

<script setup>
  
    // Basic imports
    import { ref, computed, watch } from 'vue';

    // Services imports
    import { formatFormModelValues } from "@/helpers/form.js";
    
    // definisco le props
    const props = defineProps({
        fields: {
            type: Object,
            required: true
        },
        styleType: {
            type: String,
            default: "default",
            validator: (value) => {
                const arrValues = ["default", "grid", 'staticGrid', 'customClass']; 
                return arrValues.includes(value);
            },
            required: false
        },
        customClass : {
            type: String,
            required: false
        },
        tKey: {
            type: String,
            required: false,
            default: null
        },
        instanceId: {
            required: true,
            default: 1
        },
        useCanDirective: {
            type: Boolean,
            required: false,
            default: true
        }
    });

    // definisco gli emits
    const emits = defineEmits(['update:modelValue']);

    // definisco una variabile locale per il dato
    const localData = ref(formatFormModelValues(props.fields) || {});

    // dichiaro un computed per lo style in base a props.styleType
    const styleComputed = computed(() => {
        switch (props.styleType) {
            case "grid":
                return "grid grid-cols-auto-fill-300 items-center justify-center gap-4 space-y-4";
            case "staticGrid":
                return "grid grid-cols-1 md:grid-cols-6 lg:grid-cols-12 gap-4";
            case 'customClass':
                return props.customClass;
            default:
                return "space-y-4";
        }
    });

    // definisco una funzione da esporre per aggiornare un singolo campo
    const onUpdateField = (key, value, updateKeys = ['model']) => {
        try {

            if (!localData.value.hasOwnProperty(key)) {
                throw new Error(`Key '${key}' does not exist in localData.value`);
            }

            let target = localData.value[key];

            // Usa reduce per attraversare le chiavi tranne l'ultima
            target = updateKeys.slice(0, -1).reduce((acc, currentKey) => {
                if (!acc.hasOwnProperty(currentKey)) {
                    throw new Error(`Key '${currentKey}' does not exist in the path`);
                }
                return acc[currentKey];
            }, target);

            const lastKey = updateKeys[updateKeys.length - 1];
            if (!target.hasOwnProperty(lastKey)) {
                throw new Error(`Key '${lastKey}' does not exist in the final target`);
            }

            // Imposta il valore all'ultima chiave
            target[lastKey] = value;
        } catch (error) {
            import.meta.env.VITE_DEVELOPMENT === 'true' && console.error(error.message);
        }
    };

    // definisco una funzione da esporre per ottenere il valore del campo
    const getFieldsModel = (key) => {
        return localData.value.hasOwnProperty(key) ? localData.value[key].model : null;
    };

    watch(localData, async (newValue) => {
        emits('update:modelValue', {instanceId: 1, data: newValue});
    }, { deep: true });

    // esporto le variabili
    defineExpose({
        onUpdateField,
        getFieldsModel
    });

</script>