// based imports router
import { h } from 'vue';
import { createRouter, createWebHistory, RouterView } from 'vue-router';

// import middleware
import Middleware from './middleware.js';

// first views import
import LandingView from '@/views/landing/View.vue';

// Recupero la gerarchia di ruoli
const roleHierarchy = Middleware.getRoleHierarchy();

const router = createRouter({
  history: createWebHistory(import.meta.env.VITE_BASE_URL),
  routes: [

    // HomePage
    {
      path: '',
      name: 'home',
      component: LandingView,
      meta: { requiresAuth: false }
    },

    // ConfirmRegistration
    {
      path: '/confirmRegistration/:token',
      name: 'confirmRegistration',
      component: () => import('@/views/confirmRegistration/View.vue'),
      meta: { requiresAuth: false }
    },

    // ConfirmPayment
    {
      path: '/ConfirmPayment/:checkoutSessionId',
      name: 'confirmPayment',
      component: () => import('@/views/confirmPayment/View.vue'),
      meta: { requiresAuth: false }
    },
    
    // Login
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/auth/Login.vue'),
      meta: { requiresAuth: false }
    },

    // Ranking
    {
      path: '/ranking',
      name: 'ranking',
      component: () => import('@/views/ranking/View.vue'),
      meta: { requiresAuth: false, requiredRole: roleHierarchy.user }
    },

    // Registrations
    {
      path: '/registrations',
      name: 'registrations',
      component: () => import('@/views/registrations/View.vue'),
      meta: { requiresAuth: true, requiredRole: roleHierarchy.admin }
    },

    // Admin
    {
      path: '/dashboard',
      name: 'dashboard',
      component: { render: () => h(RouterView) },
      redirect: { name: 'ranking' },
      meta: { requiresAuth: true, requiredRole: roleHierarchy.user }
    },

    // NotFound
    {
      path: '/:pathMatch(.*)*',
      name: 'notFound',
      component: () =>  import('@/views/404.vue'),
      meta: { requiresAuth: false }
    }
  
  ]
});

// Middleware per l'autenticazione
router.beforeEach((to, from, next) => {
  Middleware.handleRoute(to, from, next);
});

export default router;
