<template>
    <div 
      class="pb-12 px-4 mx-auto pt-32 md:pb-20 md:pt-40"
      :class="{'max-w-6xl': props.enableMaxWidth}"
    >
      <slot></slot>
    </div>
  </template>
  
<script setup>
  // definisco le props
  const props = defineProps({
    enableMaxWidth: {
      default: true,
      type: Boolean
    }
  });
</script>
  