export default {
    name: 'Nome e Cognome',
    email: 'Email registrata con Leghe Fantagazzetta',
    team_name: 'Nome della squadra',
    tax_data: 'Codice Fiscale',
    tel: 'Numero di telefono',
    address: 'Indirizzo',
    city: 'Città',
    postal_code: 'CAP',
    stato_pagamento_id: 'Pagato',
    id: 'Id',
    nome: 'Nome',
    nome_squadra: 'Nome squadra',
    telefono: 'Telefono',
    codice_fiscale: 'Codice Fiscale',
    codice_postale: 'CAP',
    citta: 'Città',
    metodo_pagamento_id: 'Metodo di pagamento',
    luogo_registrazione_id: 'Luogo di registrazione',
}