export default {
    welcome: "Benvenuto",
    hello: "Ciao",
    goodbye: "Arrivederci",
    please: "Per favore",
    thankYou: "Grazie",
    yes: "Sì",
    no: "No",
    cancel: "Annulla",
    confirm: "Conferma",
    save: "Salva",
    delete: "Elimina",
    edit: "Modifica",
    update: "Aggiorna",
    loading: "Caricamento",
    error: "Errore di sistema",
    success: "Azione effettuata con successo",
    home: "Home",
    settings: "Impostazioni",
    profile: "Profilo",
    notifications: "Notifiche",
    messages: "Messaggi",
    search: "Cerca",
    filter: "Filtra",
    sort: "Ordina",
    view: "Visualizza",
    more: "Altro",
    less: "Meno",
    back: "Indietro",
    next: "Avanti",
    previous: "Precedente",
    submit: "Invia",
    reset: "Resetta",
    close: "Chiudi",
    open: "Apri",
    upload: "Carica",
    download: "Scarica",
    help: "Aiuto",
    info: "Informazioni",
    termsAndConditions: "Termini e Condizioni",
    privacyPolicy: "Politica sulla Privacy",
    aboutUs: "Chi Siamo",
    contactUs: "Contattaci",
    faq: "FAQ",
    language: "Lingua",
    theme: "Tema",
    darkMode: "Modalità Scura",
    lightMode: "Modalità Chiara",
    date: "Data",
    time: "Ora",
    day: "Giorno",
    month: "Mese",
    year: "Anno",
    hour: "Ora",
    minute: "Minuto",
    second: "Secondo",
    dashboard: "Cruscotto",
    orders: "Ordini",
    products: "Prodotti",
    customers: "Clienti",
    sales: "Vendite",
    reports: "Rapporti",
    analytics: "Analisi",
    inventory: "Inventario",
    shipments: "Spedizioni",
    returns: "Resi",
    payment: "Pagamento",
    invoices: "Fatture",
    transaction: "Transazione",
    status: "Stato",
    action: "Azione",
    details: "Dettagli",
    total: "Totale",
    quantity: "Quantità",
    price: "Prezzo",
    unit: "Unità",
    description: "Descrizione",
    add: "Aggiungi",
    remove: "Rimuovi",
    update: "Aggiorna",
    searchResults: "Risultati della Ricerca",
    noResultsFound: "Nessun Risultato Trovato",
    filterBy: "Filtra per",
    sortBy: "Ordina per",
    viewDetails: "Visualizza Dettagli",
    editItem: "Modifica Articolo",
    deleteItem: "Elimina Articolo",
    confirmDelete: "Sei sicuro di voler eliminare questo elemento?",
    orderNumber: "Numero Ordine",
    orderDate: "Data Ordine",
    deliveryDate: "Data Consegna",
    paymentStatus: "Stato Pagamento",
    orderStatus: "Stato Ordine",
    shippingAddress: "Indirizzo di Spedizione",
    billingAddress: "Indirizzo di Fatturazione",
    customerName: "Nome Cliente",
    contactNumber: "Numero di Contatto",
    emailAddress: "Indirizzo Email",
    companyName: "Nome Azienda",
    department: "Reparto",
    jobTitle: "Titolo Lavorativo",
    feedback: "Feedback",
    survey: "Sondaggio",
    ratings: "Valutazioni",
    comments: "Commenti",
    notes: "Note",
    helpDesk: "Assistenza",
    liveChat: "Chat dal Vivo",
    supportTicket: "Ticket di Supporto",
    knowledgeBase: "Base di Conoscenze",
    userGuide: "Guida Utente",
    documentation: "Documentazione",
    training: "Formazione",
    tutorial: "Tutorial",
    webinar: "Webinar",
    event: "Evento",
    announcement: "Annuncio",
    reminder: "Promemoria",
    alert: "Avviso",
    warning: "Avviso",
    subscription: "Abbonamento",
    membership: "Iscrizione",
    plan: "Piano",
    upgrade: "Aggiorna",
    renewal: "Rinnovo",
    cancellation: "Cancellazione",
    refund: "Rimborso",
    credit: "Credito",
    balance: "Saldo",
    paymentMethod: "Metodo di Pagamento",
    cardNumber: "Numero della Carta",
    expirationDate: "Data di Scadenza",
    cvv: "CVV",
    billingInformation: "Informazioni di Fatturazione",
    shippingInformation: "Informazioni di Spedizione",
    orderSummary: "Riepilogo Ordine",
    customerSupport: "Supporto Clienti",
    privacySettings: "Impostazioni sulla Privacy",
    accountSettings: "Impostazioni Account",
    generalSettings: "Impostazioni Generali",
    systemSettings: "Impostazioni di Sistema",
    userManagement: "Gestione Utenti",
    accessControl: "Controllo Accesso",
    permissions: "Permessi",
    roles: "Ruoli",
    auditLog: "Registro Audit",
    backup: "Backup",
    restore: "Ripristina",
    maintenance: "Manutenzione",
    updateAvailable: "Aggiornamento Disponibile",
    version: "Versione",
    releaseNotes: "Note di Rilascio",
    changelog: "Registro Modifiche",
    user: "Utente",
    users: "Utenti",
    login: "Login",
    logout: "Logout",
    profile: "Profilo",
    changePassword: "Cambia Password",
    password: "Password",
    currentPassword: "Password Corrente",
    newPassword: "Nuova Password",
    confirmPassword: "Conferma Password",
    forgotPassword: "Hai dimenticato la password?",
    export: "Esporta",
    import: "Importa",
    share: "Condividi",
    upload: "Carica",
    download: "Download",
    downloadTemplate: "Download Template",
    uploadFile: "Upload File",
    uploadFiles: "Upload Files",
    downloadFiles: "Download Files",
    options: "Opzioni",
    dataNotFound: "N.D.",
    reload: "Ricarica",
    totals: "Totali",
    block_maiusc_active: "Blocca Maiuscole attivo",
    logout: "Esci"
}