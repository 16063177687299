<template>

    <!-- Primo Svg -->
    <div class="absolute -z-10">
        <svg class="fill-primary" xmlns="http://www.w3.org/2000/svg" width="164" height="41" viewBox="0 0 164 41" fill="none">
            <circle cx="1" cy="8" r="1" fill-opacity="0.24"></circle>
            <circle cx="1" cy="1" r="1" fill-opacity="0.16"></circle>
            <circle cx="1" cy="15" r="1"></circle>
            <circle cx="1" cy="26" r="1" fill-opacity="0.64"></circle>
            <circle cx="1" cy="33" r="1" fill-opacity="0.24"></circle>
            <circle cx="8" cy="8" r="1"></circle>
            <circle cx="8" cy="15" r="1"></circle>
            <circle cx="8" cy="26" r="1" fill-opacity="0.24"></circle>
            <circle cx="15" cy="15" r="1" fill-opacity="0.64"></circle>
            <circle cx="15" cy="26" r="1" fill-opacity="0.16"></circle>
            <circle cx="8" cy="33" r="1"></circle>
            <circle cx="1" cy="40" r="1"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 7)" fill-opacity="0.24"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 0)" fill-opacity="0.16"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 14)"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 25)" fill-opacity="0.64"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 32)" fill-opacity="0.24"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 7)"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 14)"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 25)" fill-opacity="0.24"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 150 14)" fill-opacity="0.64"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 150 25)" fill-opacity="0.16"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 157 32)"></circle>
            <circle cx="1" cy="1" r="1" transform="matrix(-1 0 0 1 164 39)"></circle>
        </svg>
    </div>

    <!-- Secondo Svg -->
    <div class="absolute -z-10">
        <svg xmlns="http://www.w3.org/2000/svg" width="432" height="160" viewBox="0 0 432 160" fill="none">
            <g opacity="0.6" filter="url(#filter0_f_2044_9)">
                <path
                    class="fill-primary"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M80 112C62.3269 112 48 97.6731 48 80C48 62.3269 62.3269 48 80 48C97.6731 48 171 62.3269 171 80C171 97.6731 97.6731 112 80 112ZM352 112C369.673 112 384 97.6731 384 80C384 62.3269 369.673 48 352 48C334.327 48 261 62.3269 261 80C261 97.6731 334.327 112 352 112Z"
                ></path>
            </g>
            <defs>
                <filter id="filter0_f_2044_9" x="0" y="0" width="432" height="160" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix"></feFlood>
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
                    <feGaussianBlur stdDeviation="32" result="effect1_foregroundBlur_2044_9"></feGaussianBlur>
                </filter>
            </defs>
        </svg>
    </div>

    <!-- Absolute Divs -->
    <div class="absolute inset-x-0 top-0 -z-10 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
    <div class="absolute inset-x-0 bottom-0 -z-10 h-px bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
    <div class="absolute inset-x-[200px] top-1/2 -z-10 h-px bg-gradient-to-r from-transparent via-primary to-transparent mix-blend-multiply"></div>
    <div class="absolute inset-x-0 top-1/2 -z-10 h-px -translate-y-[82px] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply before:absolute before:inset-y-0 before:w-24 before:animate-[line_10s_ease-in-out_infinite_both] before:bg-gradient-to-r before:via-primary"></div>
    <div class="absolute inset-x-0 top-1/2 -z-10 h-px translate-y-[82px] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply before:absolute before:inset-y-0 before:w-24 before:animate-[line_10s_ease-in-out_infinite_5s_both] before:bg-gradient-to-r before:via-primary"></div>
    <div class="absolute inset-x-[300px] top-1/2 -z-10 h-px rotate-[20deg] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
    <div class="absolute inset-x-[300px] top-1/2 -z-10 h-px -rotate-[20deg] bg-gradient-to-r from-transparent via-gray-200 to-transparent mix-blend-multiply"></div>
    <div class="absolute inset-y-0 left-1/2 -z-10 w-px -translate-x-[216px] bg-gradient-to-b from-gray-200 to-transparent mix-blend-multiply"></div>
    <div class="absolute inset-y-0 left-1/2 -z-10 w-px translate-x-[216px] bg-gradient-to-t from-gray-200 to-transparent mix-blend-multiply"></div>


</template>