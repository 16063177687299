/*
 Per aggiungere una nuova voce alla navigazione bisogna inserire nell'array un oggetto con questa struttura: 
    {
        label: [nomeVoce] (presente in src\i18n\locales\it\headers.js, se non presente non verrà tradotta),
        icon: [nomeIcona] (icone di material-symbols-outlined),
        name: [nomeRotta o url]
    }

    Per aggiungere un figlio alla voce bisogna inserire nell'array un oggetto con questa struttura:
    {
        label: [nomeVoce] (presente in src\i18n\locales\it\headers.js, se non presente non verrà tradotta),
        icon: [nomeIcona] (icone di material-symbols-outlined),
        items: [
            {
                label: [nomeVoce] (presente in src\i18n\locales\it\headers.js, se non presente non verrà tradotta),
                icon: [nomeIcona] (icone di material-symbols-outlined),
                name: [nomeRotta o url]
            },
            {
                label: [nomeVoce] (presente in src\i18n\locales\it\headers.js, se non presente non verrà tradotta),
                icon: [nomeIcona] (icone di material-symbols-outlined),
                name: [nomeRotta o url]
            }
        ]
    }

    NB. Tutti i campi presenti saranno già filtrati con la gestione dei permessi automatica.
*/

export default [
    {
        label: 'home',
        icon: 'home',
        name: 'home',
    },
    {
        label: 'Ranking posizioni',
        icon: 'bookmark_star',
        name: 'ranking',
    },
    {
        label: 'Registrazioni',
        icon: 'groups',
        name: 'registrations',
    }
]